<div class="header-body border-top-0">
    <div class="header-top header-top-default header-top-borders border-bottom-0 bg-color-light"
        style="min-height: 0px; overflow: visible;">
        <div class="container">
            <div class="header-row">
                <div class="header-column justify-content-end">
                    <div class="header-row">
                        <!-- <nav class="header-nav-top w-100 w-md-50pct w-xl-100pct">
                            <ul class="nav nav-pills d-inline-flex custom-header-top-nav-background pe-5">
                                <li class="nav-item py-2 d-inline-flex z-index-1"><span
                                        class="d-flex align-items-center p-0"><i
                                            class="bx bx-phone-call text-white"></i><a href="tel:1234567890"
                                            class="contact">&nbsp;&nbsp;09
                                            42103 7785</a></span><span
                                        class="font-weight-normal align-items-center px-0 d-none d-xl-flex ms-3"><span><i
                                                class="bx bx-message-square-detail text-white"></i>&nbsp;&nbsp;
                                        </span><a href="mailto:sales1@ginkonursery.com"
                                            class="contact">sales1@ginkonursery.com</a></span></li>
                            </ul>
                        </nav>
                        <div class="d-flex align-items-center w-100 left-content">
                            <ul class="ps-0 ms-auto mb-0 z-index-1">
                                <li class="nav-item text-color-dark d-none d-md-flex justify-content-end"
                                    style="margin-top: -13px; font-size: 15px; color: #444;"> Mon - Sat 9:00am - 6:00pm
                                    / Sunday - CLOSED </li>
                            </ul>
                            <div class="footer-logo z-index-1">
                                <ul>
                                    <li><a href="https://www.facebook.com/ginkonursery" target="_blank"><i
                                                class="bx bxl-facebook"></i></a></li>
                                    <li><a href="https://www.instagram.com/ginkonursery/?hl=en" target="_blank"><i
                                                class="bx bxl-instagram"></i></a></li>
                                    <li><a href="#" target="_blank"><i class="bx bxl-slack"></i></a></li>
                                </ul>
                            </div>
                        </div> -->
                        <div class="footer-logo z-index-1">
                            <ul>
                                <li><a href="https://www.facebook.com/ginkonursery" target="_blank"><i
                                            class="bx bxl-facebook"></i></a></li>
                                <li><a href="https://www.instagram.com/ginkonursery/?hl=en" target="_blank"><i
                                            class="bx bxl-instagram"></i></a></li>
                                <!-- <li><a href="#" target="_blank"><i class="bx bxl-slack"></i></a></li> -->
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<nav class="navbar navbar-expand-lg navbar-light">
    <div class="container">
        <a class="navbar-brand" routerLink="/"><img src="assets/img/logo.png" alt="Logo"></a>

        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse stroke" id="navbarSupportedContent">
            <ul class="navbar-nav">

                <li class="nav-item"><a class="nav-link" (click)="navigate('/')" id="aboutus">ABOUT US</a></li>

                <li class="nav-item"><a class="nav-link" (click)="navigate('services')"
                        [ngClass]="{'active' : routeLink.includes('services')}">SERVICES</a></li>

                <li class="nav-item"><a class="nav-link" (click)="navigate('projects')"
                        [ngClass]="{'active' : routeLink.includes('projects')}">PROJECTS</a></li>

                <li class="nav-item"><a class="nav-link" href="#testimonial"
                        [ngClass]="{'active' : routeLink.includes('#')}">TESTIMONIALS</a></li>

                <li class="nav-item"><a class="nav-link" (click)="navigate('contactus')"
                        [ngClass]="{'active' : routeLink.includes('contactus')}">CONTACT US</a></li>
            </ul>
        </div>
    </div>
    <!-- <div class="side-nav">
        <a class="side-nav-right cd-signup" routerLink="/"><i class='bx bx-link-external'></i>SUBSCRIBE</a>
    </div> -->
</nav>