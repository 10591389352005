<app-header></app-header>
<section class="page-title-area pos-rel" style="background-image: url(../../../../assets/img/banner2.jpg);">
    <div class="container">
        <div class="row">
            <div class="col-xl-8 offset-xl-2">
                <div class="page-title text-center pt-180">
                    <h2>Our Projects</h2>
                    <!-- <div class="breadcrumb-list text-center">
                        <ul>
                            <li><a href="#">Home</a></li>
                            <li>All Projects</li>
                        </ul>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <div class="one-among-area two-among-area">
    <div class="container">
        <div class="one-section-title">
            <span class="sub-title">WHAT WE HAVE DONE</span>

            <div>
                <h2 tooltip="This is to protect the privacy of our clients who do not wish to disclose
             any photo of their projects of private residence." flow="down">Selected Projects (Only)</h2>
            </div>


        </div>

       
    </div>
</div> -->

<section class="three-loan-area four-loan-area five-loan-area pt-100">
    <div class="container">
        <div class="one-section-title three-section-title">
            <span class="sub-title">What We have done</span>
            <h2 id="landscapesection">Selected Projects (Only)</h2>
        </div>

        <!-- <div class="sorting-menu">
            <ul>
                <li class="filter" (click)="navigate('projects')"
                    [ngClass]="{'active' : routeLink.includes('projects')}" id="project"><span>All Projects</span></li>
                <li class="filter" (click)="navigate('artificial-landscaping')"
                    [ngClass]="{'active' : routeLink.includes('artificial-landscaping')}"><span>Artificial
                        Landscaping</span></li>
                <li class="filter" (click)="navigate('landscape-supply-install')"
                    [ngClass]="{'active' : routeLink.includes('landscape-supply-install')}"><span>Landscape Supply
                        Install</span></li>
                <li class="filter" (click)="navigate('plant-nursery')"
                    [ngClass]="{'active' : routeLink.includes('plant-nursery')}"><span>Plant Nursery</span></li>

            </ul>
        </div> -->

        <!-- <div class="row">
            <div class="col-md-4">
                <div class="offer-style-card">
                    <div class="offer-image"><img
                            src="https://levi-ng.hibootstrap.com/assets/img/more-home/offer/offer-1.jpg" alt="image">
                    </div>
                    <div class="offer-content">
                        <h3>Delivering A Great Customer Experience</h3><a href="#">Read More</a>
                    </div>
                </div>
            </div>
            
        </div> -->

        <div class="service-advisor">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="advisor-img"><img
                            src="../../../assets/img/designandbuild.jpg"
                            alt="Advisor"></div>
                </div>
                <div class="col-lg-6">
                    <div class="advisor-content">
                        <h2>Design and Build</h2>
                        <div class="advisor-link"><a href="/designandbuild">VIEW
                                MORE</a><i class="bx bx-right-arrow-alt"></i></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="service-advisor">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="advisor-img"><img
                            src="../../../assets/img/implementation.jpg"
                            alt="Advisor"></div>
                </div>
                <div class="col-lg-6">
                    <div class="advisor-content">
                        <h2>Implementation</h2>
                        <div class="advisor-link"><a href="/implementation">VIEW MORE</a><i
                                class="bx bx-right-arrow-alt"></i></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="service-advisor">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="advisor-img"><img
                            src="../../../assets/img/careandmain.jpg"
                            alt="Advisor"></div>
                </div>
                <div class="col-lg-6">
                    <div class="advisor-content">
                        <h2>Care and Maintenance</h2>
                        <div class="advisor-link"><a href="/care&maintenence">VIEW MORE</a><i
                                class="bx bx-right-arrow-alt"></i></div>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="row">
            <div class="col-sm-6 col-lg-4" *ngFor="let project of projects"
                [routerLink]="['/' + project.category + '/' + project.title]" loading="lazy">
                <app-projects [project]="project"></app-projects>
            </div>
        </div> -->


        <!-- <div id="Container" class="row">
            <div class="col-sm-6 col-lg-3 mix tyre">
                <div class="loan-item">
                    <span>Home Loan</span>
                    <h3>A  Conventional Home Loan</h3>
                    <p>Migration do amet contur diisci velit suia nonnua</p>
                    <a routerLink="/home-five">READ MORE <i class='bx bx-right-arrow-alt'></i></a>
                </div>  
            </div>

            <div class="col-sm-6 col-lg-3 mix tyre web">
                <div class="loan-item">
                    <span>Renovation Loans</span>
                    <h3>Renovation Loans For Buyers</h3>
                    <p>Migration do amet contur diisci velit suia nonnua</p>
                    <a routerLink="/home-five">READ MORE <i class='bx bx-right-arrow-alt'></i></a>
                </div>  
            </div>

        </div> -->
    </div>
</section>


<app-footer></app-footer>