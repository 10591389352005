<section class="page-title-area pos-rel" style="background-image: url(../../../../assets/img/banner2.jpg);">
    <div class="container">
        <div class="row">
            <div class="col-xl-8 offset-xl-2">
                <div class="page-title text-center pt-180">
                    <h2>Our Services</h2>
                    <div class="breadcrumb-list text-center">
                        <ul>
                            <li><a href="#">Home</a></li>
                            <li>Services</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>